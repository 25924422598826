import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/kenneth/Sites/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/186884552/"
      }}>{`Tomorrow evening`}</a>{` is our twentieth meetup, and the first under our new name `}<em parentName="p">{`Type Brigade`}</em>{`. In addition to the new name, we’ve been planning something a little unconventional that we just couldn’t wait until then to share.`}</p>
    <p>{`Below are `}<strong parentName="p">{`our completely custom event numerals`}</strong>{`, designed by Type Brigade’s Assistant Organiser & Resident Letterer `}<a parentName="p" {...{
        "href": "http://instagram.com/andreahusky"
      }}>{`Andrea Husky`}</a>{` for tomorrow’s meetup:`}</p>
    <figure className="figure--breakout">
[![Andrea Husky’s gorgeous event numerals, custom designed for Type Brigade](../images/blog-our-number-twenty-by-andrea-husky.svg)](http://meetup.com/typebrigade)
      <figcaption>
The Type Brigade №20 event numerals, designed by our own [Andrea Husky](http://instagram.com/andreahusky).
      </figcaption>
    </figure>
    <p>{`Andrea is cleverly referencing `}<a parentName="p" {...{
        "href": "http://fontsinuse.com/typefaces/298/modern-no-20"
      }}>{`Modern №20`}</a>{` by `}<a parentName="p" {...{
        "href": "http://typedia.com/explore/designer/ed-benguiat/"
      }}>{`Ed Benguiat`}</a>{` for Type Brigade №20, and the result is these fantastic Didone numerals we’re proud to call our own.`}</p>
    <p>{`Modern №20 has certainly been used in `}<a parentName="p" {...{
        "href": "http://fontsinuse.com/typefaces/298/modern-no-20"
      }}>{`a wide range of places`}</a>{`, from the cover of a 70s board game to the visual identity the largest independent design consultancy in the world.`}</p>
    <figure>
[![Lingua board game](../images/blog-our-number-twenty-lingua.jpg)](http://fontsinuse.com/uses/2269/lingua)
      <figcaption>
Lingua board game, [<abbr>CC BY-NC-SA 3.0</abbr>](http://creativecommons.org/licenses/by-nc-sa/3.0/) [Board Game Geek](http://boardgamegeek.com/boardgame/5174/lingua) via [Stephen Coles of Fonts in Use](http://fontsinuse.com/uses/2269/lingua).
      </figcaption>
    </figure>
    <figure className="figure--border">
[![Pentagram](../images/blog-our-number-twenty-pentagram.png)](http://pentagram.com)
      <figcaption>
The Pentagram wordmark
      </figcaption>
    </figure>
    <figure className="figure--border">
[![Pentagram Remixed exhibition photos](../images/blog-our-number-twenty-pentagram-ps.jpg)](http://new.pentagram.com/2013/06/pentagram-remixed-opens-at-ningbo-design-biennial)
      <figcaption>
A piece from the [<cite>Pentagram Remixed</cite>](http://new.pentagram.com/2013/06/pentagram-remixed-opens-at-ningbo-design-biennial/) exhibition.
      </figcaption>
    </figure>
    <p>{`When asked about Pentagram’s visual identity, Michael Bierut offered a simple history:`}</p>
    <blockquote>
      <p parentName="blockquote">{`just the name written in the typeface Modern №20. The original five partners used the typeface in the book they published in 1972 when the firm was founded, and I think it was simply adopted as the standard way of writing the name thereafter.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Michael Bierut, `}<a parentName="strong" {...{
            "href": "http://www.underconsideration.com/speakup/archives/001946.html"
          }}>{`Our Own Worst Clients`}</a></strong></p>
    </blockquote>
    <p>{`The italic, less commonly used in identity work than the roman, has been captured in FontShop’s specimens:`}</p>
    <figure className="figure--breakout">
[![Modern №20 specimen](../images/blog-our-number-twenty-modern-no-20.png)](http://fontsinuse.com/typefaces/298/modern-no-20)
      <figcaption>
A portion of [FontShop’s specimen](http://www.fontshop.com/fonts/downloads/bitstream/modern_no_20_std_italic/) for Modern №20
      </figcaption>
    </figure>
    <p>{`Andrea shared some of her process from the numerals, too. You should `}<a parentName="p" {...{
        "href": "http://instagram.com/andreahusky"
      }}>{`follow her on Instagram`}</a>{` to see more of her fantastic lettering in progress.`}</p>
    <figure className="figure--border">
[![Sketches from our number twenty](../images/blog-our-number-twenty-sketches.jpg)](http://instagram.com/andreahusky)
      <figcaption>
Some of Andrea’s sketches
      </figcaption>
    </figure>
    <p>{`Type Brigade’s `}<a parentName="p" {...{
        "href": "../about"
      }}>{`team`}</a>{` and I are hoping to make this meetup unique, and type history and our event numerals are going to be a big part of that.`}</p>
    <h2>{`See you tomorrow`}</h2>
    <p>{`If you’re already registered for `}<strong parentName="p">{`tomorrow’s `}<a parentName="strong" {...{
          "href": "http://www.meetup.com/typebrigade/events/186884552/"
        }}>{`Type Brigade №20`}</a></strong>{`, we can’t wait to see you. The event is at `}<a parentName="p" {...{
        "href": "https://www.google.com/maps/place/948+Homer+St/@49.2780571,-123.119061,17z"
      }}>{`Mobify HQ, on the 3rd Floor of 948 Homer Street`}</a>{`. Doors open around 6:30PM with the event starting at 7:00.`}</p>
    <p>{`If you are registered but can no longer make it, please update your RSVP for `}<a parentName="p" {...{
        "href": "http://www.meetup.com/typebrigade/events/186884552/"
      }}>{`this event`}</a>{` so those on the waitlist can get a spot.`}</p>
    <p>{`If you’re on the waitlist, mention `}<a parentName="p" {...{
        "href": "http://twitter.com/typebrigade"
      }}>{`@typebrigade`}</a>{` at any time and we’ll confirm that there’s space for you as quickly as we can.`}</p>
    <p>{`See you at Type Brigade!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      